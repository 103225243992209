<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">补贴标准设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc operationControlCurr operationControlWidth"
          style="align-items: flex-start;"
        >
          <div style="width:100%">
            <div class="searchbox" style="display: flex;">
              <div title="行政区划" class="searchboxItem ci-full">
                <span class="itemLabel">行政区划:</span>
                <el-select
                  clearable
                  size="small"
                  v-model="searchData.areaId"
                  placeholder="请选择"
                  filterable
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    v-for="item in areatreeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div title="培训类型" class="searchboxItem ci-full">
                <span class="itemLabel">培训类型:</span>
                <tree
                  ref="tree"
                  @eventBtn="childBack"
                  :ruleForm="ruleForm"
                  size="small"
                  @clearParams="clearParams"
                  typeStu
                />
              </div>
              <div class="df">
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="
                    () => {
                      getData();
                    }
                  "
                  >查询</el-button
                >
              </div>
            </div>
            <div class="btnBox">
              <el-button type="primary" class="bgc-bv" round @click="addModel()"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaName"
                min-width="150"
                fixed
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                min-width="150"
              />
              <el-table-column
                label="岗位类型"
                align="left"
                show-overflow-tooltip
                min-width="80"
              >
                <template slot-scope="scope">{{
                  scope.row.postName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="行业类型"
                align="left"
                show-overflow-tooltip
                min-width="80"
              >
                <template slot-scope="scope">{{
                  scope.row.industryNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="职业/工种"
                align="left"
                show-overflow-tooltip
                min-width="150"
              >
                <template slot-scope="scope">{{
                  scope.row.occupationNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="培训等级"
                align="left"
                show-overflow-tooltip
                min-width="80"
              >
                <template slot-scope="scope">{{
                  scope.row.trainLevelName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="补贴标准(元/人)"
                align="right"
                show-overflow-tooltip
                prop="money"
                min-width="110"
              />
              <el-table-column
                label="备注"
                align="center"
                show-overflow-tooltip
                prop="remarks"
                min-width="250"
              />
              <el-table-column
                label="操作"
                align="center"
                min-width="100"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="deleteproject(scope.row.subsidyStandardId)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="editModel(scope.row)"
                    >编辑</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="补贴标准设置"
      :visible.sync="dialogFormVisible"
      width="35%"
      :before-close="closeModel"
    >
      <el-form
        ref="groupForm"
        :model="groupForm"
        style="width:100%"
        :rules="rules"
      >
        <el-form-item label="行政区划：" label-width="100px" prop="areaId">
          <el-select
            clearable
            size="small"
            v-model="groupForm.areaId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in areatreeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="培训类型："
          label-width="100px"
          prop="Trainingtype"
        >
          <tree
            ref="tree"
            @eventBtn="childBackModel"
            :ruleForm="groupForm"
            size="small"
            @clearParams="clearParamsModel"
            typeStu
          />
        </el-form-item>
        <el-form-item label="补贴标准：" label-width="100px" prop="money">
          <div class="df">
            <el-input
              v-model="groupForm.money"
              style="width: 80%;"
              size="small"
            ></el-input>
            <span style="margin-left:5px">元/人</span>
          </div>
        </el-form-item>
        <el-form-item label="备注：" label-width="100px">
          <div class="df">
            <el-input
              v-model="groupForm.remarks"
              type="textarea"
              maxlength="50"
              show-word-limit
            ></el-input>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModel">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/SubsidyStandardSetting",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      areatreeList: [],
      searchData: {
        areaId: "",
        groupForm: "",
      },
      ruleForm: {
        Trainingtype: "",
      },
      params: {},
      groupForm: {
        areaId: "",
        Trainingtype: "",
        money: "",
        remarks: "",
      },
      dialogFormVisible: false, //弹框
      paramsModel: {},
      rules: {
        areaId: [
          { required: true, trigger: "change", message: "行政区划不能为空" },
        ],
        Trainingtype: [
          { required: true, trigger: "change", message: "培训类型不能为空" },
        ],
        money: [
          { required: true, trigger: "blur", message: "补贴标准不能为空" },
          { required: true, validator: this.validatePass5, trigger: "change" },
        ],
      },
      subsidyStandardId: "",
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
    this.getareatree();
    this.searchData.Trainingtype = "";
  },
  methods: {
    validatePass5(rule, value, callback) {
      // /^\d+(\.\d{0,2})?$/;
      let reg = /^\d{1,7}(\.\d{1,2})?$/;
      if (this.groupForm.money == "") {
        callback(new Error("补贴标准不能为空"));
      } else if (!reg.test(this.groupForm.money)) {
        callback(new Error("补贴标准支持最多7位数且可到小数点后2位"));
      } else {
        callback();
      }
    },
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/settlement/subsidy/standard/page",
        params,
        pageNum,
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree?areaLevel=1").then((ret) => {
        this.areatreeList = [...ret.data];
      });
    },
    //删除
    deleteproject(subsidyStandardId) {
      this.doDel({
        url: "/biz/settlement/subsidy/standard/delete",
        msg: "确定删除吗？",
        ps: {
          type: "post",
          data: { subsidyStandardId },
        },
      });
    },
    childBackModel(params) {
      this.paramsModel = { ...params };
    },
    clearParamsModel() {
      this.paramsModel = {};
    },
    //弹框打开
    addModel(row) {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
            this.$refs["groupForm"].resetFields();
          });
    
      if (row) {
        this.subsidyStandardId = row.subsidyStandardId || "";
        let name1 = "",
          name2 = "",
          name3 = "",
          name4 = "",
          name5 = "";
        if (row.trainTypeNamePath) {
          name1 = row.trainTypeNamePath;
        }
        if (row.postName) {
          name2 = "/" + row.postName;
        }
        if (row.industryNamePath) {
          name3 = "/" + row.industryNamePath;
        }
        if (row.occupationNamePath) {
          name4 = "/" + row.occupationNamePath;
        }
        if (row.trainLevelName) {
          name5 = "/" + row.trainLevelName;
        }
        let name = name1 + name2 + name3 + name4 + name5;
        name = name.replace(/,/g, "-");
        this.groupForm = {
          Trainingtype: name,
          ...row,
        };
      } else {
          this.$nextTick(() => {
            this.$refs["groupForm"].resetFields();
          });
      }
    },
    //弹框打开
    editModel(row) {
      this.dialogFormVisible = true;
      this.subsidyStandardId = row.subsidyStandardId || "";
        let name1 = "",
          name2 = "",
          name3 = "",
          name4 = "",
          name5 = "";
        if (row.trainTypeNamePath) {
          name1 = row.trainTypeNamePath;
        }
        if (row.postName) {
          name2 = "/" + row.postName;
        }
        if (row.industryNamePath) {
          name3 = "/" + row.industryNamePath;
        }
        if (row.occupationNamePath) {
          name4 = "/" + row.occupationNamePath;
        }
        if (row.trainLevelName) {
          name5 = "/" + row.trainLevelName;
        }
        let name = name1 + name2 + name3 + name4 + name5;
        name = name.replace(/,/g, "-");
        this.groupForm = {
          Trainingtype: name,
          ...row,
        };
    },
    //弹框关闭
    closeModel() {
      this.subsidyStandardId = "";
      this.groupForm.Trainingtype = "";
      this.groupForm.remarks = "";
      this.$refs["groupForm"].resetFields();
      this.dialogFormVisible = false;
    },
    //弹框确定
    doOk() {
      this.$refs["groupForm"].validate((valid) => {
        if (valid) {
          if (this.subsidyStandardId) {
            let params = {
              areaId: this.groupForm.areaId || "",
              money: this.groupForm.money || "",
              remarks: this.groupForm.remarks || "",
              subsidyStandardId: this.subsidyStandardId,
            };
            if (this.paramsModel.trainFormId) {
              params.trainTypeId = this.paramsModel.trainFormId;
            }
            if (this.paramsModel.levelFormId) {
              params.trainLevelId = this.paramsModel.levelFormId;
            }
            if (this.paramsModel.occFormId) {
              params.occupationId = this.paramsModel.occFormId;
            }
            if (this.paramsModel.postFormId) {
              params.postId = this.paramsModel.postFormId;
            }
            if (this.paramsModel.industryFormId) {
              params.industryId = this.paramsModel.industryFormId;
            }
            this.$post("/biz/settlement/subsidy/standard/modify", params).then(
              (ret) => {
                this.closeModel();
                this.getData();
              }
            );
          } else {
            const params = {
              areaId: this.groupForm.areaId || "",
              money: this.groupForm.money || "",
              remarks: this.groupForm.remarks || "",
            };
            if (this.paramsModel.trainFormId) {
              params.trainTypeId = this.paramsModel.trainFormId;
            }
            if (this.paramsModel.levelFormId) {
              params.trainLevelId = this.paramsModel.levelFormId;
            }
            if (this.paramsModel.occFormId) {
              params.occupationId = this.paramsModel.occFormId;
            }
            if (this.paramsModel.postFormId) {
              params.postId = this.paramsModel.postFormId;
            }
            if (this.paramsModel.industryFormId) {
              params.industryId = this.paramsModel.industryFormId;
            }
            this.$post("/biz/settlement/subsidy/standard/insert", params).then(
              (ret) => {
                this.closeModel();
                this.getData();
              }
            );
          }
        } else {
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function(route) {
        if(route.name !='evaluate/SubsidyStandardSetting') return;
        if (route.params.refresh) {
          if (route.params.isAdd) {
            this.params = {};
            this.getData();
          } else {
            this.getData(-1);
          }
        } else if (sessionStorage.getItem("refresh") != 0) {
          sessionStorage.setItem("refresh", 0);

          this.params = {};
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.projectUser {
  min-width: 6rem !important;
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    background-color: #999; //设置白色背景色
  }
}
.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
</style>
